<template>
  <div>
    <WaBtn />
    <!-- header -->
    <MainBanner />

    <!-- products -->
    <div class="py-10 lg:py-20 bg-gradient-to-l from-blue-50">
      <div class="container">
        <div class="text-center">
          <p class="text-2xl font-semibold text-gray-800">
            All-in-one Display for Conferences
          </p>
          <p class="pt-4 leading-tight">
            Smart Interactive Flat Panel integrates the features of projector,
            whiteboard, advertising signage, computer, microphone, audio, etc.
          </p>
          <p class="pt-3 leading-tight">
            It is the all-in-one display for both local meeting and remote
            collaboration.
          </p>
        </div>

        <div class="mt-7">
          <div class="lg:flex lg:items-center lg:gap-3 lg:justify-center">
            <div class="lg:w-2/4 lg:order-2">
              <img
                src="/images/product-1.jpg"
                alt=""
                class="rounded-md md:w-2/3 lg:w-full md:mx-auto"
              />
            </div>
            <div class="hidden lg:block lg:w-1/4 lg:order-1">
              <div
                v-for="(item, i) in product_desktop"
                :key="i"
                class="w-1/2 pb-4 md:w-1/3 lg:ml-auto"
              >
                <img
                  :src="'/images/' + item.image + '.png'"
                  :alt="item.alt"
                  class="w-16 mx-auto"
                />
                <p
                  class="pt-2 font-semibold text-center text-gray-600 lg:text-sm"
                >
                  {{ item.alt }}
                </p>
              </div>
            </div>
            <div class="hidden lg:block lg:w-1/4 lg:order-3">
              <div
                v-for="(item, i) in product_desktop2"
                :key="i"
                class="w-1/2 pb-4 md:w-1/3"
              >
                <img
                  :src="'/images/' + item.image + '.png'"
                  :alt="item.alt"
                  class="w-16 mx-auto"
                />
                <p
                  class="pt-2 font-semibold text-center text-gray-600 lg:text-sm"
                >
                  {{ item.alt }}
                </p>
              </div>
            </div>
            <div class="mt-8 lg:hidden">
              <div class="flex flex-wrap">
                <div
                  v-for="(item, i) in product_icon"
                  :key="i"
                  class="w-1/2 pb-4 md:w-1/3"
                >
                  <img
                    :src="'/images/' + item.image + '.png'"
                    :alt="item.alt"
                    class="w-16 mx-auto"
                  />
                  <p class="pt-2 font-semibold text-center text-gray-600">
                    {{ item.alt }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- why choose interactive panel -->
    <div class="py-10 bg-gray-50">
      <div class="container">
        <div class="text-center">
          <p class="text-2xl font-semibold text-gray-800">
            WHY CHOOSE INTERACTIVE PANEL?
          </p>
        </div>

        <div class="flex flex-wrap mt-8">
          <div
            v-for="(item, i) in choose_panel"
            :key="i"
            class="w-1/2 p-1 lg:w-1/4 md:w-1/3"
          >
            <div class="p-6 bg-white rounded-lg shadow-md">
              <img
                :src="'/images/' + item.image + '.png'"
                :alt="item.alt"
                class="w-20 mx-auto"
              />
              <div class="pt-3 font-semibold text-center text-gray-600">
                <p class="text-sm leading-tight md:h-8">{{ item.alt }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- smart panel comparisson -->
    <div class="py-10">
      <div class="container">
        <div class="lg:flex">
          <div
            v-for="(item, i) in smart_panel"
            :key="i"
            class="pb-5 lg:px-2 md:w-2/3 md:mx-auto"
          >
            <!-- interactive panel -->
            <div class="bg-gradient-to-b" :class="item.bg">
              <p class="py-5 text-center text-white">
                {{ item.type }}
              </p>
              <div class="p-6 bg-white border border-gray-300">
                <p class="text-lg font-bold text-gray-700">Pros:</p>
                <ul class="pt-4 pl-4 space-y-3 text-sm">
                  <li
                    v-for="list in item.pros"
                    :key="list"
                    class="flex leading-tight"
                  >
                    <img src="/images/check.png" alt="" class="w-5 h-5" />
                    <p class="pl-2">{{ list }}</p>
                  </li>
                </ul>
                <div class="bg-gray-200 my-6 h-0.5"></div>
                <p class="text-lg font-bold text-gray-700">Cons:</p>
                <ul class="pt-4 pl-4 space-y-3 text-sm">
                  <li
                    v-for="list in item.cons"
                    :key="list"
                    class="flex leading-tight"
                  >
                    <img src="/images/delete.png" alt="" class="w-5 h-5" />
                    <p class="pl-2">{{ list }}</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- product details -->
    <div class="py-10">
      <div class="container">
        <div class="text-center">
          <p class="text-gray-600">
            Smart Interactive Flat Panel provides not just a smart panel, but
            solution for presentation, collaboration, brainstorming and
            teleconferencing.
          </p>
        </div>

        <div class="lg:flex lg:gap-2 lg:pt-10 lg:items-start">
          <div class="mt-7 lg:mt-0 lg:w-2/3">
            <div class="md:flex md:flex-wrap">
              <div
                v-for="(item, i) in product_detail"
                :key="i"
                class="pb-3 md:w-1/2 md:px-2 lg:px-1"
              >
                <div class="relative">
                  <img
                    :src="'/images/' + item.image + '.jpg'"
                    :alt="item.alt"
                    class=""
                  />
                  <div class="absolute bottom-0 right-0 w-10 text-center">
                    <p
                      class="p-2 font-semibold text-white bg-gradient-to-b from-blue-1 to-blue-2"
                    >
                      {{ item.alt }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-6 lg:mt-0 lg:w-1/3">
            <div class="md:flex md:flex-wrap">
              <div
                v-for="(item, i) in detail_number"
                :key="i"
                class="flex items-center pb-4 md:w-1/2 lg:w-full"
              >
                <div class="text-center">
                  <p
                    class="p-2 font-semibold text-white bg-gradient-to-b w-14 from-blue-1 to-blue-2"
                  >
                    {{ item.number }}
                  </p>
                </div>
                <div class="pl-3">
                  <p class="text-lg font-semibold">{{ item.p }}</p>
                  <p class="text-gray-600">{{ item.p2 }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="py-10">
      <div class="container">
        <div class="pb-4 text-center">
          <p class="text-2xl font-semibold text-gray-800">Our Solutions</p>
          <p class="pt-4 leading-tight">
            We offer easy-to-use, high-quality conferencing solutions for all
            room sizes.
          </p>
        </div>
        <img src="/images/solution.jpg" alt="" />
      </div>
    </div>

    <!-- type use -->
    <div class="lg:flex">
      <div class="relative lg:w-1/2" tabindex="0">
        <img src="/images/education.jpg" alt="" class="" />

        <div
          class="absolute top-0 left-0 w-full h-full transition duration-200 ease-linear bg-black bg-opacity-50 opacity-0 hover:opacity-100"
        >
          <div class="absolute bottom-3 left-3">
            <p class="pb-3 text-xl font-bold text-white">Education Model</p>
            <p class="py-1 text-center text-white rounded-md w-44 bg-blue-1">
              <a href="https://wa.me/60123117001">Get A Free Quote</a>
            </p>
          </div>
        </div>
      </div>
      <div tabindex="0" class="relative lg:w-1/2">
        <img src="/images/business.jpg" alt="" class="" />

        <div
          class="absolute top-0 left-0 w-full h-full transition duration-200 ease-linear bg-black bg-opacity-50 opacity-0 hover:opacity-100"
        >
          <div class="absolute bottom-3 left-3">
            <p class="pb-3 text-xl font-bold text-white">Business Model</p>
            <p class="py-1 text-center text-white rounded-md w-44 bg-blue-1">
              <a href="https://wa.me/60123117001">Get A Free Quote</a>
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- other product -->
    <div class="py-10">
      <div class="container">
        <p class="pb-5 text-2xl font-semibold text-center text-gray-800">
          Full Solution Offering
        </p>
        <carousel
          :autoplay="true"
          :loop="true"
          :responsive="{
            0: { items: 2, nav: false },
            700: { items: 4, nav: false },
            1000: { items: 6, nav: false },
          }"
        >
          <div v-for="(item, i) in other_product" :key="i" class="px-2 lg:pt-8">
            <div class="pt-6 pb-4">
              <img
                :src="'/images/' + item.image + '.jpg'"
                :alt="item.alt"
                class="mx-auto text-center"
              />
            </div>
            <div class="pt-4 text-center">
              <p
                class="text-base font-semibold leading-tight text-center text-gray-700"
              >
                {{ item.alt }}
              </p>
              <p v-show="item.p" class="pt-3 text-sm font-medium">
                {{ item.p }}
              </p>
            </div>
          </div>
        </carousel>
      </div>
    </div>

    <!-- why choose us -->
    <div
      class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-choose.jpg')"
    >
      <div class="container">
        <h1 class="text-2xl font-semibold text-center text-white">
          Why Choose Us
        </h1>

        <div class="flex flex-wrap pt-10 lg:justify-center">
          <div
            v-for="(item, i) in choose"
            :key="i"
            class="w-1/2 pb-2 text-center md:w-1/3 lg:w-1/6"
          >
            <img :src="item.image" :alt="item.alt" class="w-16 mx-auto" />
            <h1 class="pt-4 text-sm font-semibold text-white">
              {{ item.alt }}
            </h1>
          </div>
        </div>
        <div class="mt-6">
          <h1
            class="w-48 py-2 mx-auto text-center text-white transition duration-200 ease-linear transform rounded-md hover:scale-110 bg-blue-1"
          >
            <a href="https://wa.me/60123117001"
              ><i class="pr-2 fa-brands fa-whatsapp"></i>Contact Us Now!</a
            >
          </h1>
        </div>
      </div>
    </div>

    <!-- About Us -->
    <div class="bg-gradient-to-r from-hy-darkblue to-hy-blue">
      <div class="container py-10 mx-auto">
        <div class="lg:flex lg:items-center">
          <div class="text-white lg:w-1/2 lg:pr-4">
            <div class="pb-6 text-center lg:text-left">
              <h1 class="text-2xl font-semibold text-white">Who We Are</h1>
              <p class="pb-3 text-lg font-semibold leading-tight text-white">
                Unlock the Power of Collaboration with MAXHUB Interactive
                Panels!
              </p>
            </div>
            <p class="text-center lg:text-left">
              Drawing from our extensive 21-year experience in delivering
              top-notch copiers and projectors, we proudly introduce MAXHUB
              Interactive Panels to revolutionize your workspace. Seamlessly
              blend creativity and efficiency with these state-of-the-art
              panels, designed for dynamic presentations, interactive meetings,
              and effective brainstorming sessions. Experience the future of
              collaborative communication as we empower your team to achieve
              more with MAXHUB's intuitive touch controls, seamless
              connectivity, and cutting-edge features. Elevate your productivity
              and transform the way you work with MAXHUB Interactive Panels!
            </p>
          </div>
          <div class="pt-5 lg:w-1/2 lg:pt-0">
            <img src="/images/about-us.jpg" alt="About Us" class="mx-auto" />
          </div>
        </div>
      </div>
    </div>

    <!-- Enquiry -->
    <div
      id="element"
      class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/enquiry-bg.jpg')"
    >
      <div class="container">
        <div class="p-3 bg-hy-liteblue bg-opacity-70 lg:max-w-3xl lg:mx-auto">
          <p class="pt-6 font-semibold text-center text-white">
            CONTACT US FOR ALL YOUR NEEDS!
          </p>
          <h1 class="pt-1 pb-4 text-2xl font-semibold text-center text-white">
            BOOK A DEMO OR GET A FREE QUOTE NOW
          </h1>
          <p class="pb-4 text-base font-medium text-center text-white">
            Fill out the form below and we'll get back to you as soon as
            possible
          </p>
          <div class="p-4">
            <EnquiryForm />
          </div>
        </div>
      </div>
    </div>

    <!-- Contact -->
    <div class="py-10 bg-white">
      <div class="container">
        <div class="md:flex md:items-center">
          <div class="text-gray-600 md:w-1/2">
            <img src="/images/logo.png" alt="" class="mx-auto w-42" />
            <div class="flex items-center pt-6">
              <i class="pr-2 text-xl text-hy-liteblue fa-solid fa-building"></i>
              <h1 class="text-lg leading-tight">
                Hytech Office Automation (M) Sdn Bhd
              </h1>
            </div>
            <div class="flex items-center pt-5">
              <i
                class="pr-2 text-xl text-hy-liteblue fa-solid fa-location-dot"
              ></i>
              <h1 class="text-base leading-tight">
                No, 4, Jalan Sayang 3, Taman Rasa Sayang, 43200 Cheras, Selangor
              </h1>
            </div>
            <div class="flex items-center pt-5">
              <i class="pr-2 text-xl text-hy-liteblue fa-solid fa-phone"></i>
              <h1 class="text-base leading-tight">
                <a href="tel:+60123117001"> 012-3117001</a> |
                <a href="tel:+60390802000">03-90802000</a>
              </h1>
            </div>
            <div class="flex items-center pt-2">
              <i
                class="pr-2 text-xl text-hy-liteblue fa-brands fa-whatsapp"
              ></i>
              <h1 class="text-base leading-tight">
                <a href="https://wa.me/60123117001"> 012-3117001</a>
              </h1>
            </div>
          </div>
          <div class="pt-5 md:w-1/2 md:pt-0">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.0894815780784!2d101.75316500049833!3d3.0707633544744857!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc4a782d0a8b8d%3A0x15e57dd997a86490!2sHytech%20Office%20Automation%20(M)%20Sdn%20Bhd!5e0!3m2!1sen!2smy!4v1607997350676!5m2!1sen!2smy"
              width="100%"
              height="250"
              frameborder="0"
              style="border: 0"
              allowfullscreen=""
              aria-hidden="false"
              tabindex="0"
            ></iframe>
          </div>
        </div>
      </div>
    </div>

    <div class="text-xs text-center text-white bg-hy-darkblue">
      <p class="py-4">
        Website maintained by Activa Media. All Rights Reserved.
      </p>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import MainBanner from "@/components/MainBanner.vue";
import carousel from "vue-owl-carousel";
import EnquiryForm from "@/components/EnquiryForm.vue";
import WaBtn from "@/components/WaBtn.vue";

export default {
  name: "Home",
  components: {
    MainBanner,
    carousel,
    EnquiryForm,
    WaBtn,
  },
  data() {
    return {
      product_icon: [
        { image: "icon-1", alt: "Projector" },
        { image: "icon-2", alt: "Advertising Signage" },
        { image: "icon-3", alt: "Television" },
        { image: "icon-4", alt: "Whiteboard" },
        { image: "icon-5", alt: "Computer" },
        { image: "icon-6", alt: "Speakerphone" },
      ],
      product_desktop: [
        { image: "icon-1", alt: "Projector" },
        { image: "icon-2", alt: "Advertising Signage" },
        { image: "icon-3", alt: "Television" },
      ],
      product_desktop2: [
        { image: "icon-4", alt: "Whiteboard" },
        { image: "icon-5", alt: "Computer" },
        { image: "icon-6", alt: "Speakerphone" },
      ],
      product_detail: [
        { image: "detail-1", alt: "1" },
        { image: "detail-2", alt: "2" },
        { image: "detail-3", alt: "3" },
        { image: "detail-4", alt: "4" },
      ],
      detail_number: [
        { number: "1", p: "Teleconferencing", p2: "Let's Meet Online " },
        {
          number: "2",
          p: "Huddle & Brainstorming",
          p2: "Visualise Your Idea in a Better Way",
        },
        { number: "3", p: "Training", p2: "Annotate at Will" },
        {
          number: "4",
          p: "Presentation",
          p2: "Reverse Control, Stylish Your Speech",
        },
      ],
      other_product: [
        {
          image: "other-1",
          alt: "Interactive Flat Standard Series Pane",
          // p: "X3 Standard Series",
        },
        {
          image: "other-2",
          alt: "Interactive Flat Ultimate Series Panel",
          // p: "X3 Ultimate Series",
        },
        { image: "other-3", alt: "Integrated LED Wall" },
        { image: "other-4", alt: "Ultra Wide Integrated LED Wall" },
        { image: "other-5", alt: "Smart Lectern", p: "P22MA" },
        { image: "other-6", alt: "PC Module", p: "MT41" },
        { image: "other-7", alt: "Column Speaker", p: "YJ21" },
        { image: "other-8", alt: "Bluetooth Microphone", p: "BM21" },
        { image: "other-9", alt: "UC bar", p: "UC S10" },
        { image: "other-10", alt: "Camera Mount", p: "WIB8015" },
        { image: "other-11", alt: "Mirroring Box", p: "WB01" },
        { image: "other-12", alt: "Screen-sharing Dongle", p: "WT01" },
        { image: "other-13", alt: "EMR Pen", p: "SP08" },
        { image: "other-14", alt: "Pen Box", p: "PB01" },
        { image: "other-15", alt: "Mobile Stand", p: "ST26" },
        { image: "other-16", alt: "Large Mobile Stand", p: "ST23B" },
        { image: "other-17", alt: "Mobile Stand", p: "ST33" },
        { image: "other-18", alt: "Electrical Stand", p: "EST02" },
        { image: "other-19", alt: "Interactive Flat Panel", p: "L Series" },
        { image: "other-20", alt: "PC Module", p: "MT43" },
        { image: "other-21", alt: "Dual-color IR Pen", p: "SW13" },
      ],
      choose: [
        {
          image: "/images/choose-6.png",
          alt: "Free Site Consultation and Demo",
        },
        { image: "/images/choose-1.png", alt: "Direct Factory Price" },
        { image: "/images/choose-3.png", alt: "Fast Respond" },
        { image: "/images/choose-5.png", alt: "24/7 Technical Support" },
        { image: "/images/choose-2.png", alt: "Warranty Up to 36 Months" },
        { image: "/images/choose-7.png", alt: "0% Instalment Plan" },
      ],
      choose_panel: [
        {
          image: "panel-1",
          alt: "Feature-rich Smartboard",
        },
        {
          image: "panel-2",
          alt: "Multitasking Capability",
        },
        {
          image: "panel-3",
          alt: "High-Megapixel Camera",
        },
        {
          image: "panel-4",
          alt: "20 Points of Touch",
        },
        {
          image: "panel-5",
          alt: "Advanced IR Touch",
        },
        {
          image: "panel-6",
          alt: "Wireless Screen Share",
        },
        {
          image: "panel-7",
          alt: "4K Ultra HD Panel",
        },
        {
          image: "panel-8",
          alt: "Multiple Sizes Available from 65” to 98”",
        },
      ],
      smart_panel: [
        {
          bg: "from-blue-1 to-blue-2",
          type: "SMART INTERACTIVE FLAT PANEL",
          pros: [
            "Direct touch interactivity enhances engagement.",
            "Integrated software for interactive teaching and presentations.",
            "Multi-user interaction for collaborative learning.",
            "High-resolution display with vibrant visuals",
            "No need for additional projection surface or calibration.",
          ],
          cons: [
            "Requires a dedicated wall space for installation.",
            "Limited portability due to fixed positioning.",
          ],
        },
        {
          bg: "from-red-700 to-red-500",
          type: "PROJECTOR",
          pros: [
            "Cost-effective visual presentation solution.",
            "Flexible screen size for various room settings",
          ],
          cons: [
            "Lack of direct touch interactivity.",
            "Requires an external projection surface or screen.",
            "Calibration and alignment might be needed for optimal display.",
            "Image quality affected by ambient lighting.",
          ],
        },
      ],
    };
  },
};
</script>
