<template>
  <div>
    <div
      class="bg-center bg-no-repeat bg-cover md:bg-left"
      style="background-image: url('/images/bg-banner.jpg')"
    >
      <div class="container">
        <div class="py-48">
          <div class="lg:w-2/3">
            <div class="flex items-end space-x-3">
              <img src="/images/logo-white.png" alt="" class="w-20 md:w-32" />
              <div>
                <img src="/images/logo-max.png" alt="" class="h-auto w-52 lg:w-64" />
              </div>
            </div>
            <div class="pt-5 text-white">
              <h1 class="pb-3 text-5xl font-bold">
                SMART INTERACTIVE <br class="hidden md:block" />
                FLAT PANEL
              </h1>
              <p class="pt-5 text-base leading-tight">
                High-res, interactive smartboard for business & education.
                Precise touch control, natural pen writing. Engage in dynamic
                presentations & collaborative learning. User-friendly design,
                seamless integration. Transform communication.
              </p>
            </div>
            <div
              class="pt-4 space-y-2 md:flex md:space-y-0 md:space-x-1 md:pt-8 lg:pt-10"
            >
              <p
                class="py-1 text-lg text-center text-white rounded-md w-44 bg-blue-1"
              >
                <a href="https://wa.me/60123117001">
                  <i class="pr-1 fa-brands fa-whatsapp"></i>Talk to Us
                </a>
              </p>
              <p
                class="w-56 py-1 text-lg text-center text-white rounded-md bg-blue-1"
              >
                <a href="#" v-scroll-to="{ el: '#element', duration: 1500 }">
                  <i class="pr-1 fa-regular fa-envelope"></i>Get a Free Quote
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hideEnquiry: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
